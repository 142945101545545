// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

:root{
    --ion-color-primary: #F4A100;
    --ion-color-secondary: #757575;
    
    font-family: 'system-ui' !important;
    --ion-dynamic-font: 'system-ui' !important;
    --ion-font-family: 'system-ui' !important;
    strong{
        font-weight: 500 !important;
    }
}