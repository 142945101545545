/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import "@ionic/angular/css/palettes/dark.system.css";

.green{
    color: var(--ion-color-success);
}
body {
    background: #eaeaea;
}
ion-app {
    max-width: 1200px;
    margin: auto;
}

h1{
    font-weight: 500 !important;
    font-size: 1.8rem;
}

ion-item{
    ion-label{
        h1{
            font-size: 1.3rem;
        }
    }
    p{
        font-size: 1rem !important;
        color: #000 !important;
        &.small{
            color: lighten(#000, 10) !important;
        }
    }
}

ion-tab-button {
    font-size: 0.75rem;
    ion-icon{
        font-size: 30px !important;
    }
    
}

.mandatory{
    color: var(--ion-color-danger);
    font-size: 1.15rem;
}